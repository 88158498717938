import React from 'react';
import { useStoreState } from 'easy-peasy';

function OtherOptions2() {

    const otherCardSettings = useStoreState(state => state.siteSettingsModel.otherCardSettings);

    return (
        <>
            <div className="row" style={{ backgroundColor: "#f0f1f1" }}>
                <div className="col-12">
                    <p className="optional-offers">Optional credit offers</p>
                    <hr style={{ borderColor: "#EE3E2D", width: "100%", height: "1px", marginTop: "0px" }} />
                    <p className="otherOptions text-left">
                        Based on your application and credit profile there maybe be other credit offer available to you.
                        If you would like to review and consider these offers please click the button below and you will be automatically redirected to a third party website.
                    </p>
                    <p></p>
                    <p className="otherOptions text-left">Are you interested in reviewing these credit options today?</p>
                </div>
            </div>

            <div className="row">
                <button
                    className="btn pay-processing-button align-items-center"
                    style={{ width: "auto", color: "white" }}
                    onClick={() => { window.open(otherCardSettings.ICommDeclineOtherOfferUrl, '_blank') }}
                >
                    Show offers
                </button>
            </div>
        </>
    );
}

export default OtherOptions2;