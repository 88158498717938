import React from 'react';
import { useStoreState } from "easy-peasy";
import AxiosWrapper from './axios';

class AdobeScript extends React.Component {

    constructor() {
        super();
        this.Setting = useStoreState((state) => state.siteSettingsModel);
        this.axios = new AxiosWrapper();
    }

    setup = () => {
        let adobeScript = document.createElement('script');
       
        adobeScript.setAttribute('src',process.env.REACT_APP_ADOBE_URL);
        adobeScript.setAttribute('async', "");
        document.head.appendChild(adobeScript);
        console.log("Adobe Service Loaded!")
        return this
    }

    startEvent = (event) => {
        if(typeof window.adobeDataLayer !== 'undefined' ){
               
            window.adobeDataLayer.push({
                "event": event,
                campaign: {
                    utm_channel:            this.axios.findParam('utm_channel') || undefined,
                    utm_medium:             this.axios.findParam('utm_medium') || undefined,
                    utm_name:               this.axios.findParam('utm_name') || undefined,
                    utm_source:             this.axios.findParam('utm_source') || undefined,
                    utm_source_platform:    this.axios.findParam('utm_source_platform') || undefined,
                    utm_strategy:           this.axios.findParam('utm_strategy') || undefined,
                },
                pageName: 'cf | '+this.Setting.brandSettings.ProductName+' | application | '+this.Setting.mainSettings.Flow+'',
                pageCleanUrl: window.location.origin + window.location.pathname,
                pageHostname: window.location.host,
                pageQuerystring: window.location.search === "" ? undefined : window.location.search,
                pageCategory: 'cf | '+this.Setting.brandSettings.ProductName+' | application',
                pageSubcategory: undefined,
                siteSection: 'cf | '+this.Setting.brandSettings.ProductName+' | application',
                pageType: 'application',
                userState: 'view',
                userType: 'viewer'
            })
        }
    }

    event = (eventName, cardColor = '') => {
        if(typeof window.adobeDataLayer !== 'undefined' ){
           
            let event = { "event": eventName }
            
            if(eventName === 'scroll')            { event = {...event,...{scrollMilestone : '50%'}}}
            if(eventName === 'prequal_accept')    { event = {...event,...{chosen_color : cardColor.DisplayName || undefined, cardType : this.Setting.brandSettings.ProductName}}}
            if(eventName === 'apply_now_click')   { event = {...event,...{chosen_color : cardColor.DisplayName || undefined, cardType : this.Setting.brandSettings.ProductName}}}

            window.adobeDataLayer.push(event)
        }
    }


}

export default AdobeScript