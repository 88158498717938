import React, {useState, useEffect} from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useHistory } from 'react-router';
//react-bootstrap components
import Button from 'react-bootstrap/Button';

//components
import Disclaimer from './components/disclaimer';
import Terms from './components/terms';

//css
import '../../../assets/prequal/linkAccount.css';
import '../../../assets/progressBar.css';

//classes
import AxiosWrapper from '../../../services/axios';
import Proccessing from './components/proccesing';
import AudioEyeSupport from '../../../services/audioEyeSupport';

function Flinks() {
    //to be added in config
    const flinksSrc = process.env.REACT_APP_FLINK + "?demo="+ process.env.REACT_APP_FLINK_DEMO +"&redirectUrl=https://flinks.com/contact/thank-you&innerRedirect=true&theme=light&consentEnable=true&customerName=FinTech&backgroundColor=f7f7f7&foregroundColor1=000000&desktopLayout=true&headerEnable=false&institutionFilterEnable=true";
    const client = new AxiosWrapper(process.env.REACT_APP_DE_BASE_URL)
    const clientCommon = new AxiosWrapper()
    const history = new useHistory()
    //easy-peasy state declarations
    const brandSettings = useStoreState(state => state.siteSettingsModel.brandSettings);
    const setting = useStoreState(state => state.siteSettingsModel);
    const setApplicationResult = useStoreActions(actions => actions.applicationResultModel.setApplicationResult);
    const setGrandBanckDeclined = useStoreActions(actions => actions.siteSettingsModel.setGrandBanckDeclined);
    //useState
    const [iFrameSrc, setIFrameSrc] = useState("");
    const [showWidgetLoading, setShowWidgetLoading] = useState(false);
    const [isIFrameLoaded, setIsIFrameLoaded] = useState(false);
    const [widgetResult, setWidgetResult] = useState(null);
    const webAssetsBaseUrlSettings = useStoreState(state => state.siteSettingsModel.webAssetsBaseUrlSettings);
    const applicationResult = useStoreState(state => state.applicationResultModel.applicationResult);
    const [showDisclaimer, setShowDisclaimer] = useState(false);
    const [showFlinksTerms, setShowFlinksTerms] = useState(false);
    const [isTermsOpened, setIsTermsOpened] = useState(false);
    const [hasAgreed, setHasAgreed] = useState(false);
    const [showProcessing, setShowProcessing] = useState(false);
    const setdisplayResult = useStoreActions((actions) => actions.setDisplayResult);
    const setdisplayTerms = useStoreActions((actions) => actions.setDisplayPrequalTerms);
    const flinkPixels = useStoreState((state) => state.flinkPixels);
    const setFlinkPixels = useStoreActions((actions) => actions.setFlinkPixels);
    const domain = useStoreState(state => state.siteSettingsModel.domain);
    const getRoutingSettings = useStoreActions(state => state.routingSettingsModel.getRoutingSettings);
    const ada = new AudioEyeSupport();

    useEffect(() => {
        window.addEventListener('message', function(e) {
            setWidgetResult(e.data);
        });

        if(!flinkPixels){
            ada.setDYContext({ type: "FLINKPAGE" }).sendDYEvent({name : 'FlinkFlow'})
            setFlinkPixels(true)
        }
        getRoutingSettings({ Url: domain, Route: window.location.pathname});
          // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(() => {
        if(widgetResult) {
            if(widgetResult.step === "APP_MOUNTED") {
                setIsIFrameLoaded(true);
            }
            if(widgetResult.step === "REDIRECT") {
                harvestAccountDetails(widgetResult) 
                setShowProcessing(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [widgetResult])

   

    const harvestAccountDetails = async (FlinkInfo) => {
        try {
            let bulk = {
                ApplicantId : applicationResult.ref_number,
                decisionId : applicationResult.decision_id,
                websiteURL : setting.domain + "?Flinks=Initiate",
                institution : FlinkInfo.institution,
                loginId : FlinkInfo.loginId,
                token : sessionStorage.getItem("sessionToken")
            }
            //replace with applicationResult.ref_number
            let response = await client.post("initiate-flinks-application", bulk, true)
                if (response.StatusCode === "OK") {
                    let result = await client.keepPost("fetch-flinks-application-status", {receiptId : response.ReceiptId, token : sessionStorage.getItem("sessionToken")}, true, { trys : 200, seconds : 10},{ key: "ProcessingState", objetive : ["Completed", "SystemError"]});
                
                    switch(result.ProcessingState){
                        case "Completed" :
                            let result = await clientCommon.keepGet("get-submit-flinks-application-result",  {receiptId : response.ReceiptId}, false, { trys : 3, seconds : 3},{ key: "result", objetive : ["approved", "*"]});

                   
                            if(result){
                                //We overwrite GB values to prevent null triggers on declined pages because Flink flow don't move trought WebApi
                                result.IsGrandbank      = applicationResult.IsGrandbank;
                                result.GBAuthId         = applicationResult.GBAuthId;
                                result.GrandbankUrl     = applicationResult.GrandbankUrl;

                                result.result = (result.result === null) ? "sys_error" : result.result;
                                setApplicationResult(result);
                                setGrandBanckDeclined(result.IsGrandbank === true ? true : false)
                               
                                if(result.result !== null && (result.result.toUpperCase() === "APPROVED" || result.result.toUpperCase() === "OFFER") ){
                                    setdisplayTerms(true)
                                    history.push("/terms" + window.location.search); 
                                    return; 
                                }
                                setdisplayResult(true)
                                history.push("/declined" + window.location.search)
                                return;
                            }
                        break;
                        case "SystemError" :
                            setApplicationResult({result : "sys_error"});
                            setdisplayResult(true)
                            history.push("/declined" + window.location.search)
                        break;
                        default:
                        
                            setApplicationResult({result : "sys_error"});
                            setdisplayResult(true)
                            history.push("/declined" + window.location.search)
                        break;
                    }
                }
                setApplicationResult({result : "sys_error"});
                setdisplayResult(true)
                history.push("/declined" + window.location.search)
        }catch (err) {
            console.log(err)
        };
    }

 

    const handleLinkAccountClick = () => {
        setIFrameSrc(flinksSrc);
        setShowWidgetLoading(true);
    }

    const handleTermsClick = () => {
        setIsTermsOpened(true);
        setShowFlinksTerms(true)
    }

    return (
    <>
        {
            showProcessing ? 
            <Proccessing /> :
            <>
                <div className="ribbon" style={{ backgroundColor: brandSettings.Primary }}><h1>Almost There...</h1></div>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12' >
                            <h5>
                                The next step is to link your bank account. We only use the information to evaluate your application and qualify you 
                                for a potential line of credit. Please review the following disclosures and then click the button to link your bank account 
                                information and compete your application.
                            </h5>
                        </div>
                    </div>
                    <div className="row link-account-section">
                        <div className='col-xs-12 col-sm-12 col-md-6  col-lg-6' style={{marginBottom: "30px"}}>
                            <img src={(brandSettings.ProductName === "Surge") ? webAssetsBaseUrlSettings.CdnImage+"Surge/card-silver.png" : brandSettings.CardImage} alt={brandSettings.ProductName}/>
                        </div>
                        <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6'>
                            <div className='container' style={{marginTop: "10px"}}>
                                <div className='row'>
                                    <div className='col-12'>
                                        <Button style={{ backgroundColor: brandSettings.Primary, width: "100%" }} onClick={() => handleTermsClick()}>View Terms & Conditions</Button>
                                    </div>                                
                                </div>
                                <div className='row'>
                                    <div className='col-12'>
                                        <p>You are required to view the Terms and Conditions of this offer.</p>
                                    </div>                                
                                </div>  
                                <div className='row'>
                                    <div className='col-12'>
                                        <Button style={{ backgroundColor: brandSettings.Primary, width: "100%" }} onClick={() => setShowDisclaimer(true)}>About Verification</Button>
                                    </div>                                 
                                </div>  
                                <div className='row'>
                                    <div className='col-12'>
                                        <p>See more about verifying your bank account and how it pertains to this credit line offer.</p>
                                    </div>                                 
                                </div>  
                            </div>
                        </div>
                    </div>
                    <div className="row link-account-section">       
                        <div className='col-10 offset-1'>
                            <div className="form-check check-fields">
                                <div className="input-container">
                                    <input className="form-check-input" type="checkbox" onChange={(e) => setHasAgreed(e.target.checked)} />
                                    <label className="form-check-label">
                                        <h5>
                                            By clicking this box, you agree that you have read the full <span id="flinks-terms-link" onClick={() => handleTermsClick()}>Terms and conditions</span> of the {brandSettings.ProductName} Mastercard® credit card offer
                                        </h5>                                    
                                    </label>
                                </div>
                            </div>
                        </div>                
                    </div>
                    <div className="row link-account-section">       
                        <div className="col-12 text-center mb-3">
                            <Button style={{ backgroundColor: brandSettings.Secondary, minWidth: "300px" }} onClick={() => handleLinkAccountClick()} disabled={!isTermsOpened || !hasAgreed}>
                                Link Bank Account
                            </Button>
                        </div>                
                    </div>

                    <div id="row widget-wrapper ">
                        {
                            showWidgetLoading && (
                            <div lg={{span:4, offset:4}} className={`col-4 offset-4 text-center ${isIFrameLoaded ? "hide-widget" : ""}`} style={{height: "400px", paddingTop: "100px"}}>
                                <div>
                                    Loading ... 
                                </div>                            
                            </div>)
                        }           
                        
                        <div className={`col-sm-12 col-xs-12 col-md-4 offset-md-4 col-lg-4 offset-lg-4 text-center ${(!isIFrameLoaded || !hasAgreed)? "hide-widget" : ""}`}>
                            <div>
                                <iframe id="link-account" height="400"  frameBorder="0" title="flinks"
                                    src={iFrameSrc}>
                                </iframe>
                            </div>  
                        </div>

                    </div>
                </div> 

                <div id="flinks-modal-wrapper">
                    <Disclaimer props={{setShowDisclaimer: setShowDisclaimer, showDisclaimer: showDisclaimer}}/>
                    <Terms props={{setShowFlinksTerms: setShowFlinksTerms, showFlinksTerms: showFlinksTerms}}/>
                </div>
            </>
        }
    </>
  )
}

export default Flinks