import React from "react";
import { useStoreState, useStoreActions } from "easy-peasy";
import Wildcard from "../../../../services/wildcard";
import { useState } from "react";
import { useForm } from "react-hook-form";
import AxiosWrapper from "../../../../services/axios";
import { useEffect } from "react";
import PixelsService from '../../../../services/pixels';
import AudioEyeSupport from "../../../../services/audioEyeSupport";

const REDIRECTION_URL = {
  verve :    { DEVELOPMENT : "https://dev.yourvervecard.com/",     TEST : "https://test.yourvervecard.com/",     QA : "https://qa.yourvervecard.com/", PRODUCTION : "https://www.yourvervecard.com/"},
  fit :      { DEVELOPMENT : "https://dev.yourfitcard.com/",     TEST : "https://test.yourfitcard.com/",     QA : "https://qa.yourfitcard.com/", PRODUCTION : "https://www.yourfitcard.com/"},
  cerulean : { DEVELOPMENT : "https://dev.yourceruleancard.com/",  TEST : "https://test.yourceruleancard.com/",  QA : "https://qa.yourceruleancard.com/", PRODUCTION : "https://www.yourceruleancard.com/"},
  surge :    { DEVELOPMENT : "https://dev.yoursurgecard.com/",     TEST : "https://test.yoursurgecard.com/",     QA : "https://qa.yoursurgecard.com/", PRODUCTION : "https://www.yoursurgecard.com/"},
  reflex :   { DEVELOPMENT : "https://dev.yourreflexcard.com/",    TEST : "https://test.yourreflexcard.com/",    QA : "https://qa.yourreflexcard.com/", PRODUCTION : "https://www.yourreflexcard.com/"},
  revel :    { DEVELOPMENT : "https://dev.revelcard.com/",         TEST : "https://test.revelcard.com/",         QA : "https://qa.revelcard.com/", PRODUCTION : "https://www.revelcard.com/"}


}
const PREQUAL = ["surge", "reflex"];
const NOT_PREQUAL = ["cerulean", "revel", "verve", "fit"];

function Reservation() {
  const ada = new AudioEyeSupport();
  const Setting = useStoreState((state) => state.siteSettingsModel);
  const text = useStoreState((state) => state.dmText.reservation);
  const wildcard = new Wildcard();
  const axios = new AxiosWrapper();
  let [info, setInfo] = useState({
    lastName: "",
    last4ssn: "",
    zip: "",
    productName: Setting.brandSettings.ProductName,
    campaignId: axios.findParam("campaignId") || "",
  });
  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
  } = useForm({ mode: "onSubmit" });
  const [isLoading, setLoading] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [failedAttemptsCounter, setFailedAttemptsCounter] = useState(0);
  const getRoutingSettings = useStoreActions(state => state.routingSettingsModel.getRoutingSettings);
  const PixelService = new PixelsService();
  const setInitPixels = useStoreActions(actions => actions.setInitPixels);
  const initPixels = useStoreState(state => state.initPixels);

  useEffect(() => {
    if(!initPixels){
      PixelService.initialize().pageView("/reservation").event("google", "tracking", "landing");
      
      setInitPixels(true)
      ada.setup();
    }
    getRoutingSettings({ Url: Setting.domain, Route: window.location.pathname});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submit = async (data) => {
    setLoading(true);
    const reservationInfo = {
      ssn: data.last4ssn,
      lastName: data.lastName,
      zip: data.zip,
    };
    axios
      .post("/get-reservation-number", reservationInfo, true)
      .then(async (response) => {
        if (
          response.Payload.Result.toLowerCase().includes("expired") ||
          response.Payload.Result.toLowerCase().includes("not found") ||
          response.Success === false
        ) {
          setFailedAttemptsCounter(failedAttemptsCounter + 1);
          setError("last4ssn", {
            type: "custom",
            message: response.Payload.Result || "Invalid Reservation Number",
          });
          setLoading(false);
          return;
        }
        axios
          .get(`/encrypt-code/${response.Payload.Code}`, true)
          .then(async (hashedResponse) => {
            window.scrollTo(0, 0);
            setIsRedirecting(true);
            const delay = (ms) => new Promise((res) => setTimeout(res, ms));
            await delay(3000);

            let queryString = (window.location.search === "") ? "?code="+hashedResponse : window.location.search + "&code=" + hashedResponse;
            window.location.href = `${REDIRECTION_URL[response.Payload.Card.toLowerCase()][process.env.REACT_APP_CFC_APPLICANT_BUILDENV]}${queryString}`;

            setLoading(false);
            return;
          });
      })
      .catch((err) => {
        setError("reservationNumber");
        setLoading(false);
        return;
      });
  };

  const renderLastSsnError = (errors) => {
    return errors.last4ssn ? (
      <div>
        {errors.last4ssn.message.toLowerCase().includes("not found") &&
          NOT_PREQUAL.includes(
            Setting.brandSettings.ProductName.toLowerCase()
          ) && (
            <div className="error-message text-center error-message-ssn">
              {text.block1.last4ssnNotFoundNotPrequal}
            </div>
          )}

        {errors.last4ssn.message.toLowerCase().includes("not found") &&
          PREQUAL.includes(Setting.brandSettings.ProductName.toLowerCase()) && (
            <>
              <div className="error-message text-center error-message-ssn">
                {text.block1.last4ssnNotFoundPrequal}
              </div>
              <div className="form-group reservation-submit-button-container error-message-ssn-button">
                <button
                  disabled={isLoading}
                  style={{ backgroundColor: Setting.brandSettings.Tertiary }}
                  className="btn pt-2 pb-2 mt-3"
                  onClick={() => {
                    if (
                      Setting.brandSettings.ProductName.toLowerCase() ===
                      "surge"
                    ) {
                      window.location.href =
                        "https://prequal2.yoursurgecard.com";
                    } else if (
                      Setting.brandSettings.ProductName.toLowerCase() ===
                      "reflex"
                    ) {
                      window.location.href =
                        "https://prequal2.yourreflexcard.com";
                    }
                  }}
                >
                  {"Pre-Qualification"}
                </button>
              </div>
            </>
          )}

        {errors.last4ssn.message.toLowerCase().includes("expired") &&
          NOT_PREQUAL.includes(
            Setting.brandSettings.ProductName.toLowerCase()
          ) && (
            <div className="error-message text-center error-message-ssn">
              {text.block1.last4ssnExpiredNotPrequal}
            </div>
          )}

        {errors.last4ssn.message.toLowerCase().includes("expired") &&
          PREQUAL.includes(Setting.brandSettings.ProductName.toLowerCase()) && (
            <>
              <div className="error-message text-center error-message-ssn">
                {text.block1.last4ssnExpiredPrequal}
              </div>
              <div className="form-group reservation-submit-button-container error-message-ssn-button">
                <button
                  disabled={isLoading}
                  style={{ backgroundColor: Setting.brandSettings.Tertiary }}
                  className="btn pt-2 pb-2 mt-3"
                  onClick={() => {
                    if (
                      Setting.brandSettings.ProductName.toLowerCase() ===
                      "surge"
                    ) {
                      window.location.href =
                        "https://prequal2.yoursurgecard.com";
                    } else if (
                      Setting.brandSettings.ProductName.toLowerCase() ===
                      "reflex"
                    ) {
                      window.location.href =
                        "https://prequal2.yourreflexcard.com";
                    }
                  }}
                >
                  {"Pre-Qualification"}
                </button>
              </div>
            </>
          )}
      </div>
    ) : (
      <></>
    );
  };

  return (
    <>
      <div
        className="row"
        id="header-text-container"
        style={{ backgroundColor: Setting.brandSettings.Primary }}
      >
        <div className="col-12 text-center">
          <h1 id="offer-header-text" className="mt-2 mb-2">
            {wildcard.update().proccess(text.block1.title)}
          </h1>
        </div>
      </div>
      <div className="container mt-5 mb-5">
        {isRedirecting ? (
          <>
            <div className="col-lg-9 col-xs-8 brand-card-container redirection-container">
              <img
                src={Setting.brandSettings.CardImage}
                alt={`${Setting.brandSettings.ProductName} Card`}
                className="col-lg-12"
              />
              <div className="redirection-brand-name">
                {`${Setting.brandSettings.ProductName} Card`}
              </div>
              <div className="redirection-text">
                {text.block2.redirectionLine1}
              </div>
              <div className="redirection-text">
                {text.block2.redirectionLine2}
              </div>
            </div>
          </>
        ) : (
          <>
            <form onSubmit={handleSubmit(submit)}>
              <div className="row">
                <div className="col-lg-5 col-xs-6 reservation-form">
                  <div className="reservation-form-description">
                    {text.block1.reservationDescription}
                  </div>

                  <div className="form-group">
                    <label className="text-center w-100" htmlFor="lastName">
                      {text.block1.lastName}
                    </label>
                    <div className="input-container">
                      <input
                      maxLength={50}
                        value={info.lastName}
                        type="text"
                        className="form-control text-center"
                        placeholder={text.block1.lastName}
                        {...register("lastName", {
                          required: "Last Name is required",
                        })}
                        onInput={(e) => {
                          if (/[a-zA-Z]/.test(e.nativeEvent.data) || e.nativeEvent.inputType === "deleteContentBackward" || e.nativeEvent.data === "-") {
                            if(/^[a-zA-Z-]+$/.test(e.target.value) || e.target.value === ""){
                                if((e.target.value.split(" ").length - 1) < 2){
                              setInfo({...info,...{lastName: e.target.value }});
                                }
                            }
                          }
                        }}
                      />
                    </div>
                    {errors.lastName ? (
                      <div className="error-message text-center">
                        {errors.lastName.message}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="form-group">
                    <label className="text-center w-100" htmlFor="zip">
                      {text.block1.zip}
                    </label>
                    <div className="input-container">
                      <input
                        maxLength={5}
                        className="form-control text-center"
                        value={info.zip || ""}
                        onInput={(e) => {
                          e.preventDefault()
                          let value = typeof e.nativeEvent.data === "undefined" || e.nativeEvent.data === null ? e.target.value : e.nativeEvent.data;
                         
                          if ( /[0-9]/.test(value) || e.nativeEvent.inputType === "deleteContentBackward") {
                            if (/^[0-9]+$/.test(e.target.value) || e.target.value === "") {
                              setInfo({...info,...{zip: e.target.value }});
                            }
                          }
                        }}
                        placeholder={text.block1.zip}
                        {...register("zip", {
                          required: "Zip Code is required",
                        })}
                      />
                    </div>
                    {errors.zip ? (
                      <div className="error-message text-center">
                        {errors.zip.message}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="form-group">
                    <label className="text-center w-100" htmlFor="last4ssn">
                      {text.block1.last4ssn}
                    </label>
                    <div className="input-container">
                      <input
                        maxLength={4}
                        className="form-control text-center"
                        value={info.last4ssn}
                        onInput={(e) => {
                          let value =
                            typeof e.nativeEvent.data === "undefined" ||
                            e.nativeEvent.data === null
                              ? e.target.value
                              : e.nativeEvent.data;
                          if (
                            /[0-9]/.test(value) ||
                            e.nativeEvent.inputType === "deleteContentBackward"
                          ) {
                            if (
                              /^[0-9]+$/.test(e.target.value) ||
                              e.target.value === ""
                            ) {
                              setInfo({...info,...{last4ssn: e.target.value }});
                             
                            }
                          }
                        }}
                        placeholder={text.block1.last4ssn}
                        {...register("last4ssn", {
                          required: "Last 4 of SSN is required",
                        })}
                      />
                    </div>
                    {errors.last4ssn &&
                    errors.last4ssn.message && errors.last4ssn.message === "Last 4 of SSN is required" ? (
                      <div className="error-message text-center">
                        {errors.last4ssn.message}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="form-group reservation-submit-button-container">
                    <button
                      disabled={isLoading}
                      style={{ backgroundColor: Setting.brandSettings.Tertiary, color: "#fff" }}
                      className="btn pt-2 pb-2 mt-3"
                      type="submit"
                    >
                      {isLoading ? "Submitting..." : "Submit"}
                    </button>
                  </div>
                  {renderLastSsnError(errors)}
                </div>
                <div className="col-lg-1 d-xs-none d-flex justify-content-center">
                  <span className="border-left"></span>
                </div>
                <div className="col-lg-5 col-xs-6 brand-card-container">
                  <img
                    src={Setting.brandSettings.CardImage}
                    alt={`${Setting.brandSettings.ProductName} Card`}
                    className="col-lg-12"
                  />
                </div>
              </div>
            </form>
          </>
        )}
      </div>
    </>
  );
}

export default Reservation;
